import React from "react";
import './style.css';
import Marquee from "react-fast-marquee";
import { Images } from "../../Constants";

const BrandImgs = [
    {
        "imgUrl": "https://assets.gosats.io/notification/2023/11/1699547387851",
        "bgColor": "#D7EAFF",
        "worthTitle": "4.25% Rewards",
        "worthTitleColor": "#107BD4",
        "borderColor": "#107BD4"
    },
    {
        "imgUrl": "https://assets.gosats.io/notification/2023/11/1699548542002",
        "bgColor": "#EFEAFF",
        "worthTitle": "22.95% Rewards",
        "worthTitleColor": "#492F91",
        "borderColor": "rgba(197, 0, 52, 0.40)"
    },
    {
        "imgUrl": "https://assets.gosats.io/merchant/logo/png/web/myntra.png",
        "bgColor": "#FFE9D5",
        "worthTitle": "4% Rewards",
        "worthTitleColor": "#B7633C",
        "borderColor": "#E2B398"
    },
    {
        "imgUrl": "https://assets.gosats.io/merchant/logo/png/web/bigbasket.png",
        "bgColor": "#EBF9C5",
        "worthTitle": "1.5% Rewards",
        "worthTitleColor": "#617A1D",
        "borderColor": "rgba(97, 122, 29, 0.40)"
    },
    {
        "imgUrl": "https://assets.gosats.io/merchant/logo/png/web/prime.png",
        "bgColor": "#E9F9FF",
        "worthTitle": "7.5% Rewards",
        "worthTitleColor": "#00A8E1",
        "borderColor": "rgba(0, 168, 225, 0.40)"
    },
    {
        "imgUrl": "https://assets.gosats.io/merchant/logo/png/web/nykaa.png",
        "bgColor": "#FFDFEB",
        "worthTitle": "3.5% Rewards",
        "worthTitleColor": "#E0236C",
        "borderColor": "#E2B398"
    },
    {
        "imgUrl": "https://assets.gosats.io/merchant/logo/png/web/book_my_show.png",
        "bgColor": "#FFECED",
        "worthTitle": "1.75% Rewards",
        "worthTitleColor": "#E31E25",
        "borderColor": "rgba(227, 30, 37, 0.40)"
    },
    {
        "imgUrl": "https://assets.gosats.io/merchant/logo/png/web/uber.png",
        "bgColor": "#FAFAFA",
        "worthTitle": "1.75% Rewards",
        "worthTitleColor": "#000",
        "borderColor": "rgba(0, 0, 0, 0.40)"
    },
    {
        "imgUrl": "https://assets.gosats.io/merchant/logo/png/web/mamaearth.png",
        "bgColor": "#EAF9FF",
        "worthTitle": "10.63% Rewards",
        "worthTitleColor": "#1A92BF",
        "borderColor": "rgba(212, 147, 57, 0.40)"
    }
]

const BrandImgsTwo = [
    {
        "imgUrl": "https://assets.gosats.io/merchant/logo/png/web/pvr_cinemas.png",
        "bgColor": "#FFFEFA",
        "worthTitle": "5% Rewards",
        "worthTitleColor": "#D79925",
        "borderColor": "rgba(212, 147, 57, 0.40)"
    },
    {
        "imgUrl": "https://assets.gosats.io/merchant/logo/png/web/sonyliv.png",
        "bgColor": "#F8F2FF",
        "worthTitle": "18% Rewards",
        "worthTitleColor": "#6B0089",
        "borderColor": "rgba(107, 0, 137, 0.40)"
    },
    {
        "imgUrl": "https://assets.gosats.io/merchant/logo/png/web/make_my_trip.png",
        "bgColor": "#FFECED",
        "worthTitle": "2.5% Rewards",
        "worthTitleColor": "#E31E25",
        "borderColor": "rgba(227, 30, 37, 0.40)"
    },
    {
        "imgUrl": "https://assets.gosats.io/merchant/logo/png/web/croma.png",
        "bgColor": "#EAFFFE",
        "worthTitle": "2.13% Rewards",
        "worthTitleColor": "#288E89",
        "borderColor": "#23849F"
    },
    {
        "imgUrl": "https://assets.gosats.io/merchant/logo/png/web/zomato.png",
        "bgColor": "#FFFFFF",
        "worthTitle": "2% Rewards",
        "worthTitleColor": "#212426",
        "borderColor": "rgba(153, 153, 153, 1)"
    },
    {
        "imgUrl": "https://assets.gosats.io/merchant/logo/png/web/skechers.png",
        "bgColor": "#E5F3FF",
        "worthTitle": "5.25% Rewards",
        "worthTitleColor": "#0067BC",
        "borderColor": "rgba(0, 103, 188, 0.40)"
    },
    {
        "imgUrl": "https://assets.gosats.io/merchant/logo/png/web/first_cry.png",
        "bgColor": "#FFF7FB",
        "worthTitle": "1.75% Rewards",
        "worthTitleColor": "#C1539C",
        "borderColor": "#C1539C"
    },
    {
        "imgUrl": "https://assets.gosats.io/merchant/logo/png/web/pepperfry.png",
        "bgColor": "#FFE4E4",
        "worthTitle": "4.5% Rewards",
        "worthTitleColor": "#ED2D2F",
        "borderColor": "#E2B398"
    },
    {
        "imgUrl": "https://assets.gosats.io/merchant/logo/png/web/the_man_company.png",
        "bgColor": "#F8F8F8",
        "worthTitle": "6.5% Rewards",
        "worthTitleColor": "#000",
        "borderColor": "rgba(0, 0, 0, 0.40)"
    },
    {
        "imgUrl": "https://assets.gosats.io/notification/2023/11/1700484240763",
        "bgColor": "#D4FAEA",
        "worthTitle": "2.5% Rewards",
        "worthTitleColor": "#007042",
        "borderColor": "rgba(0, 112, 66, 0.40)"
    }
]   

const Brands = () => {
    return (
        <div className=' favBrandsListContainer'>
            <div className='favBrandsListTitle'>
                Buy Gift Cards of Top Brands
            </div>
            <div className='favBrandsListSubTitle'>
                Earn up to <span className="favBrandsListSubTitleLinear"> 20% in Gold or Sats </span> on the purchase with top brands.
            </div>
            <div>
                <div className='rightLayout'>
                </div>
                <div className='leftLayout'>
                </div>
                <div className='brandContainer'>
                    <Marquee speed={50} gradientColor='none' direction='rtl'>
                        <div className='brandSubContainerOne'>
                            {
                                BrandImgs.map((data, index) => (
                                    <div className='brandImageContainerRowOne' style={{ background: data.bgColor }} key={index}>
                                        <img src={data.imgUrl} alt="images" className='brandImages' />
                                        <div className="brandStrokeContainer">
                                            <div style={{background: data.borderColor}} className="brandStroke"></div>
                                        </div>
                                        <div style={{ color: data.worthTitleColor }} className='worthPrice'>{data.worthTitle}</div>
                                    </div>
                                ))
                            }
                        </div>
                    </Marquee>

                    <Marquee speed={50} gradientColor='none'>
                        <div className='brandSubContainerOne'>
                            {
                                BrandImgsTwo.map((data, index) => (
                                    <div className='brandImageContainerRowOne' style={{ background: data.bgColor }} key={index}>
                                        <img src={data.imgUrl} alt="images" className='brandImages' />
                                        <div className="brandStrokeContainer">
                                            <div style={{background: data.borderColor}} className="brandStroke"></div>
                                        </div>
                                        <div style={{ color: data.worthTitleColor }} className='worthPrice'>{data.worthTitle}</div>
                                    </div>
                                ))
                            }
                        </div>
                    </Marquee>
                </div>
            </div>
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 instantRewardContainer">
                <div className="col-sm-4 col-md-3 col-lg-4 col-xl-3 instantReward">
                    Get Instant Rewards On <br />
                    <span className="instantRewardLinear">
                        100+ Leading Brands
                    </span>
                </div>
                <div className="instantImgContainer">
                    <img src={Images.instantImg} alt=" " className="instantImg" />
                </div>
            </div>
        </div>
    );
}

export default Brands;