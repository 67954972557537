const Strings = {
    //ACCUMULATE_BITCOIN: 'Accumulate free Bitcoin rewards at all your favourite brands in India',
    LANDING_HEADER_TITLE: 'Say Goodbye To Traditional Rewards',
    ACCUMULATE_BITCOIN:'Transform Your Purchases Into Valuable Assets On Every Spend',
    SHOP_AND_EARN_SUBTITLE: 'Spend INR with GoSats and be rewarded with free Satoshis on everything!',
    BITCOIN_VALUE_SUBTITLE: '1000 rupees of credit card points would have expired by now!',
    TESTIMONIALS_TITLE: 'Customers Know Best',
    TESTIMONIALS_SUBTITLE: "Don't listen to us, listen to them",
    REWARDS_TITLE: 'Drive In The Concept Of Investing When You Spend',
    REWARDS_SUBTITLE: 'Never Expire',
    SPEND_EARN_TITLE: 'Withdrawals',
    SPEND_EARN_SUBTITLE: 'Withdraw without worrying about the withdrawal charges.',
    GROWTH_TITLE:'Growth',
    GROWTH_TITLE_SUBTITLE: 'Unlock the potential for your wealth, offering rewarding returns for the future.',
    YOURS_FOREVER: 'Yours Forever',
    YOURS_FOREVER_SUBTITLE: 'Lifetime validity of your rewards.',
    REWARD_CARD_TITLE: "India’s first prepaid card that rewards",
    //REWAD_CARD_SUBTITLE: 'Download the App, Load INR, Spend INR and get free Bitcoin. It’s that simple.',
    REWAD_CARD_SUBTITLE: 'Download the App, Load INR, Spend INR and get free Sats. It’s that simple.',
    FAQ_TITLE: 'Frequently Asked Questions',
    FAQ_SUBTITLE: "Everything you need to know about the product & satoshis. Can't find the answer you are looking for?",
    VISION_TITLE: 'We are building a better world',
    VISION_SUB_TITLE_ONE: 'So why do we think Bitcoin will help make the world better? There is a lot of negativity around. It has mostly to do with 0 to little understanding of the space.',
    VISION_SUB_TITLE_TWO: 'What is the role of money in human civilisation? Money is a medium of exchange. It is a good purely to be exchanged for other goods.  Over millenia, money has taken many forms from the barter system to precious metals to paper money and now digital money.',
    OUR_VISION_TITLE: 'Our Vision',
    OUR_VISION_SUB_TITLE: 'The vision of GoSats is to bring Bitcoin into the hands of everyone, and usher in the Bitcoin standard - a world where everyone owns and understands Bitcoin.',
    OUR_MISSION_TITLE: 'Our Mission',
    OUR_MISSION_SUB_TITLE: 'GoSats is on a mission to get Bitcoin to every shopper, spender and saver. Join us on this journey to #StackSats with GoSats',
    ABOUT_LANDINGPAGE_TITLE: 'Your Gateway To ',
    ABOUT_LANDINGPAGE_SUBTITLE: 'GoSats is on a mission to bring Bitcoin investing to the masses in a risk-free manner. Redeem your bitcoin to get discounts on brands, buy NFTs, stake your bitcoin and play blockchain games.',
    TEAM_SECTION_TITLE: 'The People Behind GoSats',
    TEAM_SECTION_SUBTITLE: "We couldn’t be more grateful to this fantastic team in helping bring our unique Cashbacks and Rewards to India.",
    ABOUT_COMPANY_TITLE: 'We are building a better world',
    ABOUT_COMPANY_SUBTITLE_ONE: 'So why do we think Bitcoin will help make the world better? There is a lot of negativity around. It has mostly to do with 0 to little understanding of the space. ',
    ABOUT_COMPANY_SUBTITL_TWO: 'What is the role of money in human civilisation? Money is a medium of exchange. It is a good purely to be exchanged for other goods. You acquire money so you can exchange it for other goods. Over millenia, money has taken many forms from the barter system to precious metals to paper money and now digital money. These are put in 2 categories- Hard and Soft money. Hard money is something that is difficult to produce and hence functions as the best form of money. Over centuries, Gold has been the hardest money. Hence, it was used by governments to back currencies. But decades earlier, countries started moving away from the Gold standard and started printing paper currency (Fiat money) without hard money backing it. This caused extreme inflation across decades, the effects observed most in under developed economies such as Argentina, Venezuela, Turkey, most african countries and now recently Sri Lanka. I knew somebody that worked in the UAE for 30 years. He accumulated his life savings of 1 Million Dirhams and transferred it to his Sri Lankan bank account. Due to the recent economic crisis in Sri Lanka, his money is now worth just 400,000 Dirhams. 60% of his life savings gone because of currency devaluation. The banks even stopped the transfer of currency abroad. This is just 1 story. There are numerous cases around the world. Bitcoin fixes this. It combines the hard properties of Gold (which means bitcoin production is fixed) and transfer properties of Fiat money. Yes bitcoin is volatile today because it hasn’t found its price. The more bitcoin rises, the lesser the volatility. I want to work on the toughest problems in the world. Bitcoin is the hardest money and I want to help people accumulate this asset in the most risk-free manner possible. I hope one day Governments will realise the importance of bitcoin in today’s world.',
    ABOUT_COMPANY_FOUNDER_TITLE:'- Mohammed Roshan, Co-Founder & CEO',
    COPYRIGHT_TITLE:'2025 Saffroncoin Solutions Pvt Ltd. All rights reserved',
    GOSATS_COMMUNITY_GST_INFO:'GSTIN: 29ABCCS0470N1ZZ',
    GOSATS_COMMUNITY_TITLE: 'Join Our Community',
    GOSATS_COMMUNITY_SUBTITLE: 'Join us to keep up with the updates, new feature announcements and learn more about earning sats.',
    GOSATS_COMMUNITY_TELEGRAM_TITLE: 'Join Our Telegram',
    GOSATS_COMMUNITY_WHATSAPP_TITLE: 'Join Our WhatsApp',
    GOSATS_COMMUNITY_Twitter_TITLE: 'Join Our Twitter',
    CARD_REVOLUTION: 'Experience the magic of earning Sats rewards every time you make a transaction',
    CARD_FEATURE_TITLE: 'Why GoSats Card?',
    CARD_FEATURE_SUBTITLE_ONE: 'Earn Rewards on Everything',
    CARD_FEATURE_SUBTITLE_TWO: 'Limitless Rewards',
    CARD_FEATURE_SUBTITLE_THREE: 'More Rewards on Vouchers',
    CARD_FEATURE_SUBTITLEONE: 'Get 1.5% back on every spend on your prepaid card!',
    CARD_FEATURE_SUBTITLETWO: 'Earning Rewards with no limits and expiry. Stack your way to the moon!',
    CARD_FEATURE_SUBTITLETHREE: 'With the GoSats Card, Earn higher percentages on your favorite brands!',
    CHOOSE_CARD_SUBTITLE: 'Make a choice between Elite and Intro tier of cards, and join the revolution today!',
    CHOOSE_CARD_TITLE: "Choose The Card That's Right For You",
    CHOOSE_CARD_DOWNLOAD_TITLE: "Get free virtual card, and start your journey today!",
    SATSPAY_TITLE: "Introducing SatsPay!",
    SATSPAY_SUBTITLE: "Redeem with your earned rewards instantly with SatsPay.",
    SATSPAY_FTR_TITLE: "Secure, Easy, And Rewarding",
    SATSPAY_FAQ_TITLE: "What is the GoSats SatsPay feature, and who can use it?",
    SATSPAY_FAQ_SUBTITLE_ONE: "The GoSats SatsPay feature allows users to make payments by scanning a QR code at participating merchants using their GoSats card balance or rewards.",
    SATSPAY_FAQ_SUBTITLE_TWO: "SatsPay is an exclusive service provided to our GoSats Elite cardholders"
}

export default Strings;